import { ReactNode, useContext, useState } from "react";
import ModalContext from "./ModalContext";

interface ModalProviderProps {
    children: ReactNode; 
  }

  
export const ModalProvider: React.FC<ModalProviderProps> = ({ children }) => {
    const [isVisible, setIsVisible] = useState(false);
  
    const showModal = () => setIsVisible(true);
    const hideModal = () => setIsVisible(false);
  
    return (
      <ModalContext.Provider value={{ isVisible, showModal, hideModal }}>
        {children}
      </ModalContext.Provider>
    );
};

export const useModal = () => {
    const context = useContext(ModalContext);
    if (!context) {
      throw new Error('useModal must be used within a ModalProvider');
    }
    return context;
  };