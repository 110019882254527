import React, { useState, useEffect, ReactNode } from 'react';
import './Preloader.scss'; 

import LogoGif from '../../assets/sections/home/logo.gif';
import WeeksBackground from '../../assets/sections/home/weeks/weeks-background.png';
import HomeBackground from '../../assets/sections/home/00_BG.png';
import DiscordLogo from '../../assets/sections/home/weeks/discord.svg';
import XLogo from '../../assets/sections/home/weeks/x.svg';
import TelegramLogo from '../../assets/sections/home/weeks/telegram.svg';
import SkillFulLogo from '../../assets/skillful_ai_logo.svg';
import BackIcon from  '../../assets/sections/header/arrow_back.svg';

import { getWeekAssetsSource } from '../../helpers/Week';

const loadImage = (src: string) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = src;
      img.onload = resolve;
      img.onerror = () => {
        reject(new Error(`Failed to load image at ${src}`));
      };
    });
  };

  const loadVideo = (src: string) => {
    return new Promise((resolve, reject) => {
      const video = document.createElement('video');
      video.src = src;
      video.onloadeddata = resolve;
      video.onerror = () => {
        reject(new Error(`Failed to load video at ${src}`));
      };
      video.load();
    });
}; 
  

interface PreloaderProps  {
    children: ReactNode;
};

const Preloader: React.FC<PreloaderProps> = ({ children }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    Promise.all([
      loadImage(LogoGif),
      loadImage(WeeksBackground),
      loadImage(HomeBackground),
      loadImage(getWeekAssetsSource().lock),
      loadImage(DiscordLogo),
      loadImage(XLogo),
      loadImage(TelegramLogo),
      loadImage(SkillFulLogo),
      loadImage(BackIcon),
      loadVideo(getWeekAssetsSource().intro)
    ])
    .then(() => {
      setLoading(false); 
    })
    .catch(error => {
      console.error('Error loading assets:', error);
    });
  }, []);

  if (loading) {
    return (
        <div className="preloader-container">
          <div className="circle-spinner"></div>
        </div>
      );
  }

  return <div>{children}</div>;;
};

export default Preloader;
