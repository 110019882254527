import week0Lock from "../assets/sections/home/weeks/01_LOCK_0000.png";
import week1Lock from "../assets/sections/home/weeks/01_LOCK_0050.png";
import week2Lock from "../assets/sections/home/weeks/01_LOCK_0100.png";
import week3Lock from "../assets/sections/home/weeks/01_LOCK_0150.png";
import week4Lock from "../assets/sections/home/weeks/01_LOCK_0200.png";
import week5Lock from "../assets/sections/home/weeks/01_LOCK_0250.png";
import week6Lock from "../assets/sections/home/weeks/01_LOCK_0300.png";
import week7Lock from "../assets/sections/home/weeks/01_LOCK_0350.png";
import week8Lock from "../assets/sections/home/weeks/01_LOCK_0400.png";
import week9Lock from "../assets/sections/home/weeks/01_LOCK_0450.png";
import week10Lock from "../assets/sections/home/weeks/01_LOCK_0500.png";
import week11Lock from "../assets/sections/home/weeks/01_LOCK_0550.png";
import week12Lock from "../assets/sections/home/weeks/01_LOCK_0600.png";


export const currentWeek = parseInt(process.env.REACT_APP_CURRENT_WEEK || "0");

const week3CorrectWord = process.env.REACT_APP_WEEK3_CORRECT_WORD || "";
const week3ResultWord = process.env.REACT_APP_WEEK3_RESULT_WORD || "";
const week4CorrectWord = process.env.REACT_APP_WEEK4_CORRECT_WORD || "";
const week4ResultWord = process.env.REACT_APP_WEEK4_RESULT_WORD || "";
const bigStringOfWordsPart1 = process.env.REACT_APP_BIG_STRING_OF_WORDS_PART_1 || "";
const bigStringOfWordsPart2 = process.env.REACT_APP_BIG_STRING_OF_WORDS_PART_2 || "";
const bigStringOfWordsPart3 = process.env.REACT_APP_BIG_STRING_OF_WORDS_PART_3 || "";
const bigStringOfWordsPart4 = process.env.REACT_APP_BIG_STRING_OF_WORDS_PART4 || "";

const week8CorrectWord1 = process.env.REACT_APP_WEEK8_CORRECT_WORD_1 || "";
const week8ResultWord1 = process.env.REACT_APP_WEEK8_RESULT_WORD_1 || "";

const week8CorrectWord2 = process.env.REACT_APP_WEEK8_CORRECT_WORD_2 || "";
const week8ResultWord2 = process.env.REACT_APP_WEEK8_RESULT_WORD_2 || "";

const week8CorrectWord3 = process.env.REACT_APP_WEEK8_CORRECT_WORD_3 || "";
const week8ResultWord3 = process.env.REACT_APP_WEEK8_RESULT_WORD_3 || "";


const week9CorrectWord = process.env.REACT_APP_WEEK9_CORRECT_WORD || "";
const week9ResultWord = process.env.REACT_APP_WEEK9_RESULT_WORD || "";

export const getWeekAssetsSource = () => {
  switch (currentWeek) {
    case 1: return { intro: 'https://getoursheckles.s3.us-west-2.amazonaws.com/SKILLFULL_AI_SAFE_1.mp4', lock: week1Lock };
    case 2: return { intro: 'https://getoursheckles.s3.us-west-2.amazonaws.com/SKILLFULL_AI_SAFE_2.mp4', lock: week2Lock };
    case 3: return { intro: 'https://getoursheckles.s3.us-west-2.amazonaws.com/SKILLFULL_AI_SAFE_3.mp4', lock: week3Lock };
    case 4: return { intro: 'https://getoursheckles.s3.us-west-2.amazonaws.com/SKILLFULL_AI_SAFE_4.mp4', lock: week4Lock };
    case 5: return { intro: 'https://getoursheckles.s3.us-west-2.amazonaws.com/SKILLFULL_AI_SAFE_5.mp4', lock: week5Lock };
    case 6: return { intro: 'https://getoursheckles.s3.us-west-2.amazonaws.com/SKILLFULL_AI_SAFE_6.mp4', lock: week6Lock };
    case 7: return { intro: 'https://getoursheckles.s3.us-west-2.amazonaws.com/SKILLFULL_AI_SAFE_7.mp4', lock: week7Lock };
    case 8: return { intro: 'https://getoursheckles.s3.us-west-2.amazonaws.com/SKILLFULL_AI_SAFE_8.mp4', lock: week8Lock };
    case 9: return { intro: 'https://getoursheckles.s3.us-west-2.amazonaws.com/SKILLFULL_AI_SAFE_9.mp4', lock: week9Lock };
    case 10: return { intro: 'https://getoursheckles.s3.us-west-2.amazonaws.com/SKILLFULL_AI_SAFE_10.mp4', lock: week10Lock };
    case 11: return { intro: 'https://getoursheckles.s3.us-west-2.amazonaws.com/SKILLFULL_AI_SAFE_11.mp4', lock: week11Lock };
    case 12: return { intro: 'https://getoursheckles.s3.us-west-2.amazonaws.com/SKILLFULL_AI_SAFE_12.mp4', lock: week12Lock };
    default: return { intro: 'https://getoursheckles.s3.us-west-2.amazonaws.com/SKFAI_SAFE_00_loop.mp4', lock: week0Lock };
  }
};

export const checkWordWeek3 = (inputWord: string) => {

  const inputWordLower = inputWord.toLowerCase();
  const correctWordLower = week3CorrectWord.toLowerCase();

  if (inputWordLower === correctWordLower) {
    return week3ResultWord;
  } else {
    const wordsArray = [
      ...bigStringOfWordsPart1.split(' '),
      ...bigStringOfWordsPart2.split(' '),
      ...bigStringOfWordsPart3.split(' '),
      ...bigStringOfWordsPart4.split(' ')
    ];
    const randomIndex = Math.floor(Math.random() * wordsArray.length);
    return wordsArray[randomIndex];
  }
};

export const checkWordWeek4 = (inputWord: string) => {

  if (inputWord === week4CorrectWord) {
    return week4ResultWord;
  } else {
    const wordsArray = [
      ...bigStringOfWordsPart1.split(' '),
      ...bigStringOfWordsPart2.split(' '),
      ...bigStringOfWordsPart3.split(' '),
      ...bigStringOfWordsPart4.split(' ')
    ];
    const randomIndex = Math.floor(Math.random() * wordsArray.length);
    return wordsArray[randomIndex];
  }
};

export const checkWordWeek8 = (inputWord: string) => {

  const inputWordLower = inputWord.toLowerCase();
  const correctWordLower1 = week8CorrectWord1.toLowerCase();

  const correctWordLower2 = week8CorrectWord2.toLowerCase();

  const correctWordLower3 = week8CorrectWord3.toLowerCase();

  if (inputWordLower === correctWordLower1) {
    return week8ResultWord1;
  } else if (inputWordLower === correctWordLower2) {
    return week8ResultWord2;
  } else if (inputWordLower === correctWordLower3) {
    return week8ResultWord3;
  } else {
    const wordsArray = [
      ...bigStringOfWordsPart1.split(' '),
      ...bigStringOfWordsPart2.split(' '),
      ...bigStringOfWordsPart3.split(' '),
      ...bigStringOfWordsPart4.split(' ')
    ];
    const randomIndex = Math.floor(Math.random() * wordsArray.length);
    return wordsArray[randomIndex];
  }
};


export const checkWordWeek9 = (inputWord: string) => {

  const inputWordLower = inputWord.toLowerCase();
  const correctWordLower = week9CorrectWord.toLowerCase();

  if (inputWordLower === correctWordLower) {
    return week9ResultWord;
  } else {
    const wordsArray = [
      ...bigStringOfWordsPart1.split(' '),
      ...bigStringOfWordsPart2.split(' '),
      ...bigStringOfWordsPart3.split(' '),
      ...bigStringOfWordsPart4.split(' ')
    ];
    const randomIndex = Math.floor(Math.random() * wordsArray.length);
    return wordsArray[randomIndex];
  }
};