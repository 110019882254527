import './Lock.scss';
import { currentWeek, getWeekAssetsSource } from '../../helpers/Week';
import { useNavigate } from 'react-router-dom';
import LogoGif from '../../assets/sections/home/logo.gif';



const Lock = () => {
  
  const navigate = useNavigate();

  const handleClick = (buttonId: number) => {
    navigate(`/Weeks/${buttonId + 1}`);
  };

  const renderButtons = () => {
    const buttons = [];
    for (let i = 0; i < 12; i++) {
      const weekDisabled = i > (currentWeek - 1);
      buttons.push(
        <button
          disabled={weekDisabled}
          key={i}
          className={`circle-button ${weekDisabled ? '': 'circle-button--enabled'} button-${i} `}
          onClick={() => handleClick(i)}
        />
      );
    }
    return buttons;
  };

  return (
    <div className="circle-container" style={{backgroundImage: `url(${  getWeekAssetsSource().lock })`}}>
      <div className="circle">
        {renderButtons()}
      </div>
      <img src={LogoGif} alt="Logo" className='centered-gif'/>
    </div>
  );
};

export default Lock;
