import "./Week3.scss";
import { GradientButtonType } from "../../../models/GradientButtonType";
import { useState } from "react";
import { checkWordWeek3 } from "../../../helpers/Week";
import { GradientButton, WordCheck } from "../../../components";

const Week3 = () => {

  const [result, setResult] = useState<string | null>(null);

  return (
    <div className="week3">
      <h1>Clues</h1>
      <div className="button-wrapper">
        <GradientButton
          label="Riddle"
          type={GradientButtonType.Purple}
          onClick={() => {window.open('https://twitter.com/SkillfulAI/status/1746985872795295993', '_blank')}}
        />
        <GradientButton
          label="Clue"
          type={GradientButtonType.Purple}
          onClick={() => {window.open('https://twitter.com/jd_urenaz/status/1747840692674601294', '_blank')}}
        />
        <div className="puzzle">
          <p>Puzzle</p>
          <div className="circle-wrapper">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </div>
    <WordCheck result={result} onCheckWord={(word:string)=>  setResult(checkWordWeek3(word))}/>
    </div>
  );
};

export default Week3;
