import React, { useState } from 'react';
import './WordCheck.scss';
import TextInput from '../TextInput/TextInput';
import GradientButton from '../GradientButton';
import { GradientButtonType } from '../../models/GradientButtonType';
import InfoCircleIcon from "../../assets/sections/home/weeks/info-circle-solid.svg";

interface WordCheckProps {
  result: string | null;
  onCheckWord: (word: string) => void;
}

const WordCheck: React.FC<WordCheckProps> = ({ onCheckWord, result }) => {
 
    const [word, setWord] = useState("");
    const [previousWord, setPreviousWord] = useState<string | null>(null);
    
    const handleWordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setWord(event.target.value);
    };

  return (
    <div className="word-wrapper">
        <div className="word-wrapper__field">
          <TextInput
            placeholder="Type a word"
            onChange={handleWordChange}
            value={word}
          />
          {result && (
            <div className="word-wrapper__label">
              <img src={InfoCircleIcon} alt="Info" />
              <p>The result is: {result}</p>
            </div>
          )}
        </div>

        <GradientButton
          label="Check Word"
          type={GradientButtonType.OrangePink}
          onClick={()=>{
            if(word.length > 0 && previousWord !== word){
                onCheckWord(word);
                setPreviousWord(word)
              }
          }}
        />
      </div>
  );
};

export default WordCheck;
