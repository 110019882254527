import React from 'react';
import './TextInput.scss'; 

interface TextInputProps {
    placeholder: string;
    onChange:(event: React.ChangeEvent<HTMLInputElement>) => void; 
    value: string;
}

const TextInput: React.FC<TextInputProps>  = ({ placeholder, onChange, value }) => {
  return (
    <div className="input-container">
      <input
        type="text"
        placeholder={placeholder}
        onChange={onChange}
        value={value}
        className="text-input"
      />
    </div>
  );
};

export default TextInput;
