import GradientButton from "../../../components/GradientButton/GradientButton";
import "./Week2.scss";
import { GradientButtonType } from "../../../models/GradientButtonType";

const Week2 = () => {
  return (
    <div className="week2">
      <h1>Clues</h1>
      <div className="button-wrapper">
        <GradientButton
          label="Word of the Week"
          type={GradientButtonType.Purple}
          onClick={() => {window.open('https://twitter.com/SkillfulAI/status/1744782879371018720', '_blank')}}
        />
        <GradientButton
          label="AMA word"
          type={GradientButtonType.Purple}
          onClick={() => {window.open('https://twitter.com/SkillfulAI/status/1745527481346449586?s=20', '_blank')}}
        />
        <GradientButton
          label="Riddle"
          type={GradientButtonType.Purple}
          onClick={() => {window.open('https://twitter.com/SkillfulAI/status/1746197431170048013?s=20', '_blank')}}
        />
      </div>
    </div>
  );
};

export default Week2;
