import GradientButton from "../../../components/GradientButton/GradientButton";
import "./Week9.scss";
import { GradientButtonType } from "../../../models/GradientButtonType";
import { WordCheck } from "../../../components";
import { useState } from "react";
import { checkWordWeek9 } from "../../../helpers/Week";

const Week9 = () => {
  const [result, setResult] = useState<string | null>(null);

  return (
    <div className="week9">
      <h1>Clues</h1>
      <div className="button-wrapper">
        <GradientButton
          label="Riddle"
          type={GradientButtonType.Purple}
          onClick={() => {
            window.open(
              "https://x.com/SkillfulAI/status/1763025447632117845?s=20",
              "_blank"
            );
          }}
        />
        <GradientButton
          label="Riddle"
          type={GradientButtonType.Purple}
          onClick={() => {
            window.open(
              "https://x.com/SkillfulAI/status/1763627312711336373?s=20",
              "_blank"
            );
          }}
        />

        <GradientButton
          label="Riddle"
          type={GradientButtonType.Purple}
          onClick={() => {
            window.open(
              "https://x.com/SkillfulAI/status/1764510484814794864?s=20",
              "_blank"
            );
          }}
        />
      </div>
      <WordCheck result={result} onCheckWord={(word:string)=>  setResult(checkWordWeek9(word))}/>
    </div>
  );
};

export default Week9;
