import "./Week.scss";
import BackgroundImage from "../../assets/sections/home/weeks/weeks-background.png";
import DiscordIcon from "../../assets/sections/home/weeks/discord.svg";
import XIcon from "../../assets/sections/home/weeks/x.svg";
import TelegramIcon from "../../assets/sections/home/weeks/telegram.svg";
import { useNavigate, useParams } from "react-router-dom";
import { Header } from "../../components";
import Week1 from "./Week1/Week1";
import { useEffect } from "react";
import { currentWeek } from "../../helpers/Week";
import Week2 from "./Week2/Week2";
import Week3 from "./Week3/Week3";
import Week4 from "./Week4/Week4";
import Week5 from "./Week5/Week5";
import Week6 from "./Week6/Week6";
import Week7 from "./Week7/Week7";
import Week8 from "./Week8/Week8";
import Week9 from "./Week9/Week9";
import Week10 from "./Week10/Week10";
import Week11 from "./Week11/Week11";
import Week12 from "./Week12/Week12";

const Week = () => {
  let { week } = useParams();

  const weekNumber = parseInt(week ? week : "0");

  const navigate = useNavigate();

  useEffect(() => {
    if (isNaN(weekNumber) || weekNumber < 1 || weekNumber > currentWeek) {
      navigate("/");
    }
  }, [weekNumber, navigate]);

  const renderWeekComponent = () => {
    switch (weekNumber) {
      case 1:
        return <Week1 />;
      case 2:
        return <Week2 />;
      case 3:
        return <Week3 />;
      case 4:
        return <Week4 />;
      case 5:
        return <Week5 />;
      case 6:
        return <Week6 />;
      case 7:
        return <Week7 />;
      case 8:
        return <Week8 />;
      case 9:
        return <Week9 />;
      case 10:
        return <Week10 />;
      case 11:
        return <Week11 />;
      case 12:
        return <Week12 />;
      // ...
      default:
        return null;
    }
  };

  return (
    <div className="week-page">
      <Header />
      <div className="week-page__content">
        <h2 className="week-page__title">GET OUR SHECKLES</h2>

        <h1 className="week-page__week">WEEK {week}</h1>
        {renderWeekComponent()}
      </div>
      <div className="week-page__social-media">
        <a href="https://t.me/+oNknk6vJj-0wODgx"  target="_blank" rel="noreferrer">
          <img src={TelegramIcon} alt="Telegram" />
        </a>
        <a href="https://discord.com/invite/WhAYqkXYkg"  target="_blank" rel="noreferrer">
          <img src={DiscordIcon} alt="Discord" />
        </a>
        <a href="https://twitter.com/SkillfulAI"  target="_blank" rel="noreferrer">
          <img src={XIcon} alt="X" />
        </a>
      </div>
      <div className="background-image-wrapper">
        <img
          src={BackgroundImage}
          alt="background"
          draggable="false"
          onDragStart={(e) => e.preventDefault()}
        />
      </div>
    </div>
  );
};

export default Week;
