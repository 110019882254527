import GradientButton from "../../../components/GradientButton/GradientButton";
import "./Week12.scss";
import { GradientButtonType } from "../../../models/GradientButtonType";

const Week12 = () => {

  
  return (
    <div className="week12">
      <h1>Clues</h1>
      <div className="button-wrapper">
        <GradientButton
          label="Puzzle"
          type={GradientButtonType.Purple}
          onClick={() => {
            window.open(
              "https://x.com/SkillfulAI/status/1770299900854456647?s=20",
              "_blank"
            );
          }}
        />
        <GradientButton
          label="Doors mini Game"
          type={GradientButtonType.Purple}
          onClick={() => {
            window.open(
              "https://x.com/Coldjack_Crypto/status/1771745195366756644?s=20",
              "_blank"
            );
          }}
        />

        <GradientButton
          label="Puzzle"
          type={GradientButtonType.Purple}
          onClick={() => {
            window.open(
              "https://x.com/SkillfulAI/status/1772124353385136333?s=20",
              "_blank"
            );
          }}
        />
      </div>
     
    </div>
  );
};

export default Week12;
