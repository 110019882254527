import React, { ReactNode } from 'react';
import './Modal.scss'; 
import { useModal } from '../../contexts/ModalContext/ModalProvider';

interface ModalProps {
    children: ReactNode; 
}

export const Modal: React.FC<ModalProps> = ({ children }) => {
  const { isVisible, hideModal } = useModal();

  if (!isVisible) return null;

  return (
    <div className='modal-container'>
      <button className='close-button' onClick={hideModal}>×</button>
      {children}
    </div>
  );
};
