import { Link, useNavigate } from 'react-router-dom'

import Logo from '../../assets/skillful_ai_logo.svg'
import './Header.scss'
import { useContext } from 'react'
import DeviceContext from '../../contexts/DeviceContext/DeviceContext'
import arrowBack from '../../assets/sections/header/arrow_back.svg'


const Header = () => {

  const deviceContext = useContext(DeviceContext);

  const navigate = useNavigate();

  if (!deviceContext) {
    throw new Error('DeviceProvider should wrap the components');
  }

  const { isMobile } = deviceContext;


  const renderDesktopMenu = () => (
    <div className="header-content-container desktop">
      <div className="logo-wrapper">
        <Link to="/"><img src={Logo} alt="SKILLFUL AI LOGO" /></Link>
      </div>
      <div className='back-wrapper'  onClick={()=>navigate('/')}>
      Back
      </div>
    
    </div>
  )

  const renderMobileMenu = () => (
    <div className="header-content-container mobile">
      <div className='back-wrapper'>
      <img src={arrowBack} alt='back' onClick={()=>navigate('/')} />
      </div>
      <div className="logo-wrapper">
        <Link to="/"><img src={Logo} alt="SKILLFUL AI LOGO" /></Link>
      </div>
    </div>
  )

  return (
    <header>
      <div className="header-container">
        <>{!isMobile ? renderDesktopMenu() : renderMobileMenu()}</>

      </div>
    </header>
  )
}

export default Header
