import GradientButton from "../../../components/GradientButton/GradientButton";
import "./Week1.scss";
import { GradientButtonType } from "../../../models/GradientButtonType";

const Week1 = () => {
  return (
    <div className="week1">
      <h1>Clues</h1>
      <div className="button-wrapper">
        <GradientButton
          label="First Clue"
          type={GradientButtonType.Purple}
          onClick={() => {window.open('https://twitter.com/TylerHillYT/status/1743295163378581898', '_blank')}}
        />
        <GradientButton
          label="Picture Clue"
          type={GradientButtonType.Purple}
          onClick={() => {window.open('https://twitter.com/SkillfulAI/status/1743694699620077639', '_blank')}}
        />
        <GradientButton
          label="Riddle"
          type={GradientButtonType.Purple}
          onClick={() => {window.open('https://x.com/SkillfulAI/status/1744024615213510671?s=20', '_blank')}}
        />
      </div>
    </div>
  );
};

export default Week1;
