import { Routes, Route } from 'react-router-dom'
import { Home, Week} from '../pages'

const Main = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/Weeks/:week" element={<Week />} />
    </Routes>
  )
}
export default Main
