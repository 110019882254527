import GradientButton from "../../../components/GradientButton/GradientButton";
import "./Week8.scss";
import { GradientButtonType } from "../../../models/GradientButtonType";
import { WordCheck } from "../../../components";
import { useState } from "react";
import { checkWordWeek8 } from "../../../helpers/Week";

const Week8 = () => {

  const [result, setResult] = useState<string | null>(null);
  
  return (
    <div className="week8">
      <h1>Clues</h1>
      <div className="button-wrapper">
        <GradientButton
          label="Puzzle"
          type={GradientButtonType.Purple}
          onClick={() => {
            window.open(
              "https://x.com/SkillfulAI/status/1760535035252269362?s=20",
              "_blank"
            );
          }}
        />
        <GradientButton
          label="Puzzle"
          type={GradientButtonType.Purple}
          onClick={() => {
            window.open(
              "https://x.com/SkillfulAI/status/1761139158548918753?s=20",
              "_blank"
            );
          }}
        />

        <GradientButton
          label="Puzzle"
          type={GradientButtonType.Purple}
          onClick={() => {
            window.open(
              "https://x.com/SkillfulAI/status/1761980940551618592?s=20",
              "_blank"
            );
          }}
        />
      </div>
      <WordCheck result={result} onCheckWord={(word:string)=>  setResult(checkWordWeek8(word))}/>
    </div>
  );
};

export default Week8;
