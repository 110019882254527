import React from 'react';

interface ModalContextProps {
  isVisible: boolean;
  showModal: ()=>void;
  hideModal: ()=>void;
}

const ModalContext = React.createContext<ModalContextProps | undefined>(undefined);

export default ModalContext;
