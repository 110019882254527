import GradientButton from "../../../components/GradientButton/GradientButton";
import "./Week6.scss";
import { GradientButtonType } from "../../../models/GradientButtonType";

const Week6 = () => {
  return (
    <div className="week6">
      <h1>Clues</h1>
      <div className="button-wrapper">
        <GradientButton
          label="Puzzle"
          type={GradientButtonType.Purple}
          onClick={() => {
            window.open(
              "https://x.com/skillfulai/status/1754891727066792433?s=46&t=YWNiQ5g53ohH-lTuCRrpIg",
              "_blank"
            );
          }}
        />
        <GradientButton
          label="Puzzle"
          type={GradientButtonType.Purple}
          onClick={() => {
            window.open(
              "https://x.com/SkillfulAI/status/1756034560398143699?s=20",
              "_blank"
            );
          }}
        />

        <GradientButton
          label="Word Search"
          type={GradientButtonType.Purple}
          onClick={() => {
            window.open(
              "https://skaichads.club/week6",
              "_blank"
            );
          }}
        />
      </div>
    </div>
  );
};

export default Week6;
