import GradientButton from "../../../components/GradientButton/GradientButton";
import "./Week7.scss";
import { GradientButtonType } from "../../../models/GradientButtonType";

const Week7 = () => {
  return (
    <div className="week7">
      <h1>Clues</h1>
      <div className="button-wrapper">
        <GradientButton
          label="Picture Clue"
          type={GradientButtonType.Purple}
          onClick={() => {
            window.open(
              "https://x.com/SkillfulAI/status/1757256309299179917?s=20",
              "_blank"
            );
          }}
        />
        <GradientButton
          label="Puzzle"
          type={GradientButtonType.Purple}
          onClick={() => {
            window.open(
              "https://x.com/SkillfulAI/status/1758597035068883209?s=20",
              "_blank"
            );
          }}
        />

        <GradientButton
          label="Circle Puzzle"
          type={GradientButtonType.Purple}
          onClick={() => {
            window.open(
              "https://x.com/0xStinky_/status/1758953049660497986?s=20",
              "_blank"
            );
          }}
        />
      </div>
    </div>
  );
};

export default Week7;
