import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import Main from "./routes/Routes";
import "./App.css";
import { DeviceProvider } from "./contexts";
import { ModalProvider } from "./contexts/ModalContext/ModalProvider";
import Preloader from "./components/Preloader/Preloader";

function App() {
  return (
    <Router>
      <Preloader>
        <ModalProvider>
          <DeviceProvider>
            <div className="page-content">
              <Main />
            </div>
          </DeviceProvider>
        </ModalProvider>
      </Preloader>
    </Router>
  );
}

export default App;
