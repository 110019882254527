import React, { useContext, useEffect, useRef, useState } from "react";
import "./Home.scss";
import { GradientButton, Lock } from "../../components";
import { GradientButtonType } from "../../models/GradientButtonType";
import { useLocation } from "react-router-dom";
import { getWeekAssetsSource, currentWeek } from "../../helpers/Week";
import { useModal } from "../../contexts/ModalContext/ModalProvider";
import { Modal } from "../../components/Modal/Modal";
import { DeviceContext } from "../../contexts";
import appleIcon from "../../assets/sections/home/apple.png";
import windowsIcon from "../../assets/sections/home/windows.png";
import gameGif from "../../assets/sections/home/game.gif";

const Home = () => {
  const [videoEnded, setVideoEnded] = useState(false); // State to track video status
  const { showModal } = useModal();

  const [showDownloadModel, setShowModalDownload] = useState(false);

  const videoRef = useRef<HTMLVideoElement>(null);

  const videoPlayedKey = `week${currentWeek}VideoPlayed`;

  const deviceContext = useContext(DeviceContext);

  const isNotMobile = !deviceContext?.isMobile;

  useEffect(() => {
    window.scrollTo(0, 0);

    const videoPlayed = sessionStorage.getItem(videoPlayedKey);

    if (videoPlayed) {
      setVideoEnded(true);
    }
  }, [videoPlayedKey]);

  const { hash } = useLocation();

  useEffect(() => {
    if (hash) {
      let element = document.getElementById(hash.substr(1));
      if (element)
        element.scrollIntoView({ block: "start", behavior: "smooth" });
    }
  }, [hash]);

  const handleVideoEnd = () => {
    setVideoEnded(true);
    sessionStorage.setItem(videoPlayedKey, "true");
  };

  const ModalRulesContent: React.FC = () => {
    return (
      <div className="modal-content">
        <p className="modal-text">
          1. Must be willing to get on a Twitter Spaces or call if you win, as
          we have something special to talk about at the end of the campaign.
        </p>
        <p className="modal-text">
          2. You must have all the puzzle rewards to claim the prize
        </p>
        <p className="modal-text__small">
          (This will become more clear over time).
        </p>
        <p className="modal-text">
          3. This is the website to keep track of things -{" "}
          <a
            href="http://www.getoursheckles.com"
            target="_blank"
            rel="noopener noreferrer"
            className="modal-link"
          >
            www.getoursheckles.com
          </a>
        </p>
        <p className="modal-text">Verify you are on the correct site</p>
        <p className="modal-text">
          4. Having notifications on for our twitter is highly recommended - the
          first person to get all the words will win.
        </p>
        <p className="modal-text">
          5. Discord SKAI chads role do have a slight advantage.
        </p>
      </div>
    );
  };

  const ModalDownloadContent: React.FC = () => {
    return (
      <div className="modal-content">
        <p className="modal-text">The Hunt: Treasure Island</p>

        <div>
        <img src={gameGif} alt="Treasure Island" className = "gameGif"/>
        </div>
        

        <p className="modal-description"><b>Keen on winning $10 000?</b><br /><br />Download our Treasure Island game on Either <br />Mac or Windows to find all the hidden clues for our weekly challenges!</p>

        <button className="provider-button" onClick={() => window.open('https://nftpixels.art/TreasureIsland/MacOS/TreasureIsland_MacOS.zip', '_blank')}>
          <div>
            <img src={appleIcon} width={20} alt="apple-download" />
            Download on Mac
          </div>
        </button>

        <button className="provider-button" onClick={() => window.open('https://nftpixels.art/TreasureIsland/Windows/TreasureIsland_Windows.zip', '_blank')} >
          <div>
            <img src={windowsIcon} width={20} alt="windows-download" />
            Download on Windows
          </div>
        </button>
      </div>
    );
  };

  const AppHome = () => {
    return (
      <div className="App-home">
        <Lock></Lock>

        <div className="App-home__button">
          <GradientButton
            label="RULES"
            type={GradientButtonType.OrangePink}
            onClick={() => {
              setShowModalDownload(false);
              showModal();
            }}
          />
        </div>

        <button
          className="download-button"
          onClick={() => {
            setShowModalDownload(true);
            showModal();
          }}
        >
          Download
        </button>

        <Modal>
          {showDownloadModel ? <ModalDownloadContent /> : <ModalRulesContent />}
        </Modal>
      </div>
    );
  };

  return (
    <>
      {isNotMobile ? (
        !videoEnded ? (
          <div className="video-container">
            <video
              ref={videoRef}
              width="100%"
              autoPlay
              muted
              onEnded={handleVideoEnd}
            >
              <source src={getWeekAssetsSource().intro} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        ) : (
          <AppHome />
        )
      ) : (
        <AppHome />
      )}
    </>
  );
};

export default Home;
