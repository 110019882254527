import "./Week4.scss";
import { GradientButtonType } from "../../../models/GradientButtonType";
import { useState } from "react";
import { checkWordWeek4 } from "../../../helpers/Week";
import { WordCheck , GradientButton} from "../../../components";

const Week3 = () => {
  const [result, setResult] = useState<string | null>(null);


  return (
    <div className="week3">
      <h1>Clues</h1>
      <div className="button-wrapper">
        <GradientButton
          label="Riddle"
          type={GradientButtonType.Purple}
          onClick={() => {window.open('https://twitter.com/SkillfulAI/status/1749515456111603933?t=afJZEmxn6iuXRwPX8KK6GQ&s=19', '_blank')}}
        />

        <div className="puzzle">
          <p>Puzzle</p>
          <a href="https://twitter.com/8BitSam_/status/1749866476696424947"  target="_blank" rel="noreferrer"> Mothers addiction</a>
        </div>

        <GradientButton
          label="Free Word"
          type={GradientButtonType.Purple}
          onClick={() => {window.open('https://twitter.com/SkillfulAI/status/1751049633462137216?s=20', '_blank')}}
        />
      </div>
      <WordCheck result={result} onCheckWord={(word:string)=>  setResult(checkWordWeek4(word))}/>
    </div>
  );
};

export default Week3;
