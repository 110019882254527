import React, { useState } from 'react';
import './GradientButton.scss';
import { GradientButtonType } from '../../models/GradientButtonType';

interface GradientButtonProps {
  label: string;
  type: GradientButtonType;
  href?: string;
  to?: string;
  target?: React.AnchorHTMLAttributes<HTMLAnchorElement>["target"];
  rel?: React.AnchorHTMLAttributes<HTMLAnchorElement>["rel"];
  onClick?: () => void;
  disabled?: boolean;
}

const GradientButton: React.FC<GradientButtonProps> = ({ label, type, onClick, target, to, href, rel, disabled }) => {
  const [isHovered, setHovered] = useState(false);

  const commonProps = {
    className: `gradient-button ${type} ${disabled && "disabled"}`,
    onMouseEnter: () => setHovered(true),
    onMouseLeave: () => setHovered(false)
  };

  const renderContent = () => {
    return isHovered && disabled ? "Coming Soon" : label;
  };

  if (href) {
    return (
      <a {...commonProps}
      href={disabled ? undefined : href}  
      target={disabled ? undefined : target}
      rel={disabled ? undefined : rel}
      onClick={e => {
        if (disabled) {
          e.preventDefault();
        }
      }}        
   >
     <span>{renderContent()}</span>
   </a>
    );
  }

  return (
    <button {...commonProps}
    onClick={disabled ? undefined : onClick}
    disabled={disabled}
    >
      <span>{renderContent()}</span>
    </button>
  );
};

export default GradientButton;
