import GradientButton from "../../../components/GradientButton/GradientButton";
import "./Week11.scss";
import { GradientButtonType } from "../../../models/GradientButtonType";

const Week11 = () => {

  
  return (
    <div className="week11">
      <h1>Clues</h1>
      <div className="button-wrapper">
        <GradientButton
          label="Puzzle"
          type={GradientButtonType.Purple}
          onClick={() => {
            window.open(
              "https://twitter.com/SkillfulAI/status/1768121227837677815?s=20",
              "_blank"
            );
          }}
        />
        <GradientButton
          label="Puzzle"
          type={GradientButtonType.Purple}
          onClick={() => {
            window.open(
              "https://twitter.com/SkillfulAI/status/1768873666941456850?s=20",
              "_blank"
            );
          }}
        />

        <GradientButton
          label="Puzzle"
          type={GradientButtonType.Purple}
          onClick={() => {
            window.open(
              "https://twitter.com/SkillfulAI/status/1769585298546020807?s=20",
              "_blank"
            );
          }}
        />
      </div>
     
    </div>
  );
};

export default Week11;
