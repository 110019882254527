import GradientButton from "../../../components/GradientButton/GradientButton";
import "./Week5.scss";
import { GradientButtonType } from "../../../models/GradientButtonType";

const Week5 = () => {
  return (
    <div className="week5">
      <h1>Clues</h1>
      <div className="button-wrapper">
        <GradientButton
          label="Puzzle"
          type={GradientButtonType.Purple}
          onClick={() => {
            window.open(
              "https://twitter.com/0xStinky_/status/1752059453225255016",
              "_blank"
            );
          }}
        />
        <GradientButton
          label="Puzzle"
          type={GradientButtonType.Purple}
          onClick={() => {
            window.open(
              "https://x.com/SkillfulAI/status/1752795312148619609?s=20",
              "_blank"
            );
          }}
        />

        <GradientButton
          label="Murder Mystery"
          type={GradientButtonType.Purple}
          onClick={() => {
            window.open(
              "https://x.com/SkillfulAI/status/1754217167195611329?s=20",
              "_blank"
            );
          }}
        />
      </div>
    </div>
  );
};

export default Week5;
